import axios from "axios";
import jQuery from 'jquery';
import { getLocale} from "./tools";
import { loading } from "./loader/loader";
import { parallax } from "./home-parallax";
import moment from 'moment';
import Vue from 'vue';
import PriceRange from "../components/PriceRange";
import PhoneNumberInput from '../components/PhoneNumberInput';
import VueTelInput from "vue-tel-input";

window.axios = axios;
window.jQuery = jQuery;
window.$ = jQuery;
window.Vue = Vue;
window.moment = moment;

window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

const locale = getLocale();
moment.locale(locale);

Vue.component('price-range', PriceRange);
Vue.component('phone-number-input', PhoneNumberInput);
Vue.component('vue-tel-input', VueTelInput);

loading();

parallax()


document.addEventListener("DOMContentLoaded", function (event) {
 new Vue({
    el: '#app'
  });
});


