<template>
  <div class="PriceRange">
      <div class="PriceRange__range">
        <ul id="price-range">
          <template v-for="range in ranges">
            <li :value="range.range_min" class="PriceRange__range-label" :style="positionLeft(range.range_min)">
              {{ range.range_formatted }}&nbsp;€
            </li>
          </template>
        </ul>
        <input type="range"
               class="PriceRange__input"
               list="price-range"
               :min="min" :max="maxInputValue"
               v-model="rangeValue"
               @change="handleRangeChange"/>
      </div>
      <div class="PriceRange__amount">
        <span class="PriceRange__amount-label">{{ translate('amountLabel')}}</span>
        <span class="PriceRange__amount-price">{{ `${translate('amountPrice')} ${nestyPrice} %`}}</span>
      </div>
  </div>
</template>
<script>
  import {money} from "../lib/tools";
  import {translate} from "../lib/translate";

  export default {
    name: "price-range",
    props: [
      'ranges',
    ],
    computed: {
      min: function(){
        return Math.min(...this.ranges.map(range => range.range_min));
      },
      max: function (){
        return Math.max(...this.ranges.map(range => range.range_min));
      },
      maxInputValue: function() {
        let half = this.max / 3;
        return this.max+half;
      },
    },
    data: function() {
      return {
        rangeValue: 0,
        nestyPrice: 0,
      }
    },
    mounted() {
      this.rangeValue = parseInt(this.max / this.$props.ranges.length);
      this.handleRangeChange()
    },
    methods: {
      money,
      translate,
      handleRangeChange() {
        let result = this.$props.ranges.filter( item => parseInt(item.range_min) <= parseInt(this.rangeValue > 0 ? this.rangeValue-1 : 0));

        //this.nestyPrice = Math.max(...result.map(item => item.percentage));
        this.nestyPrice = result[result.length - 1].percentage;
      },
      positionLeft: function(range) {
        let percent = (range / this.maxInputValue) * 100;
        return `left: ${percent}%`;
      },
    }
  }
</script>
