import Burger from "./burger-menu";

let menuTimeout;

export const closeNav = () => {
  const header = document.querySelector('.Header');
  const burgerIcon = document.querySelector('.BurgerIcon');
  const backdrop = document.querySelector('.Backdrop');
  const body = document.querySelector('body');

  clearTimeout(menuTimeout);

  menuTimeout = setTimeout(() => {
    Burger.reverse();
    burgerIcon.classList.remove('BurgerIcon__close');
    backdrop.classList.remove('Backdrop-active');
    header.classList.remove('Header--open');
    body.classList.remove('no-scroll');
  }, 100);
}

export const openNav = () => {
  const header = document.querySelector('.Header');
  const burgerIcon = document.querySelector('.BurgerIcon');
  const backdrop = document.querySelector('.Backdrop');
  const body = document.querySelector('body');

  Burger.play();
  burgerIcon.classList.add('BurgerIcon__close');
  body.classList.add('no-scroll')

  clearTimeout(menuTimeout)
  menuTimeout = setTimeout( () => {
    header.classList.add('Header--open');
    backdrop.classList.add('Backdrop-active');

    $('.Header').animate({
      scrollTop: $('.Header').offset().top - 50,
    }, 500);

  }, 300)
}

/*const $menu = document.querySelector('.Header');
document.addEventListener('mouseup', e => {
  if($menu.classList.contains('Header--open') && !$menu.is(e.target) && $menu.has(e.target).length === 0) {
    closeNav();
  }
}); */



