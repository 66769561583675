import SlimSelect from "slim-select";

export const initSelects = () => {
  const selects = document.querySelectorAll('.js-slim-select');

  if(selects && selects.length > 0) {
    selects.forEach(select => {
      new SlimSelect({
        select: select,
        showSearch: false,
        onChange: (info) => {
          $(`input[name="${select.name}"]`).val(info.value)
        }
      });
    })
  }
}

export const closeInputArea = (target, type = 'area') => {
  const singleSelect = $(`.FormGroup__select-area[data-type="${type}"] .ss-single-selected`);
  const ssArrow = $(`.FormGroup__select-area[data-type="${type}"] .ss-arrow span`) ;
  const ssContent = $(`.FormGroup__select-area[data-type="${type}"] .ss-content`);

  singleSelect.removeClass('ss-open-below');
  ssArrow.removeClass('arrow-up').addClass('arrow-down');
  ssContent.removeClass('ss-open');
}


export const openInputArea = (target, type = 'area') => {
  const singleSelect = $(target);
  const ssArrow = singleSelect.find('.ss-arrow span') ;
  const ssContent = singleSelect.parent().find('.ss-content');

  singleSelect.addClass('ss-open-below');
  ssArrow.removeClass('arrow-down').addClass('arrow-up');
  ssContent.addClass('ss-open');
}

