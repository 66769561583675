
require('./owl-carousel-custom/owl')

export const initOwlCarousel = () => {



  $('.Loader__carousel').owlCarousel({
    dots: false,
    nav: false,
    responsive: {
      0: {
        items: 3,
        margin: 9,
        loop: true,
        autoplay: true,
        autoplayTimeout: 0,
        autoplaySpeed: 3000,
        autoplayHoverPause: false,
        slideTransition: 'linear',
      },
      1200: {
        items: 3,
        margin: 12,
        loop: true,
        autoplay: true,
        autoplayTimeout: 0,
        autoplaySpeed: 5000,
        autoplayHoverPause: false,
        slideTransition: 'linear',
      },
      1440: {
        items: 4,
        margin: 12,
        loop: true,
        autoplay: true,
        autoplayTimeout: 0,
        autoplaySpeed: 5000,
        autoplayHoverPause: false,
        slideTransition: 'linear',
      }
    }
  });

  $('.Page404__slider').owlCarousel({
    dots: false,
    smartSpeed: 800,
    responsive: {
      0: {
        items: 2,
        margin: 9,
        autoplay: false,
        loop: false,
      },
      992: {
        items: 4,
        autoplay: false,
        loop: false,
        margin: 12,
      }
    }
  });

  $('.Contact__carousel').owlCarousel({
    dots: false,
    smartSpeed: 800,
    responsive: {
      0: {
        items: 3,
        margin: 9,
        autoplay: true,
        loop: true,
      },
      992: {
        items: 4,
        margin: 12,
        autoplay: true,
        loop: true,
      }
    }
  });
}

export const initLightboxCarousel = () => {
  $('.Lightbox__carousel').owlCarousel({
    dots:true,
    dotsData: false,
    nav: true,
    smartSpeed: 800,
    navText: ['<div class="Lightbox__btn Lightbox__btn-prev"><svg width="55" height="13" viewBox="0 0 55 13" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M0.49902 6.5L10.499 12.2735V0.726497L0.49902 6.5ZM54.8232 5.5H9.49902V7.5H54.8232V5.5Z" fill="black"/></svg></div>','<div class="Lightbox__btn Lightbox__btn-next"><svg width="55" height="13" viewBox="0 0 55 13" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M54.501 6.50048L44.501 0.726982L44.501 12.274L54.501 6.50048ZM0.176758 7.50049L45.501 7.50048L45.501 5.50048L0.176758 5.50049L0.176758 7.50049Z" fill="black"/></svg></div>'],
    responsive: {
      0: {
        margin: 10,
        items: 1,
        loop: true,
        autoplay: false,
        stagePadding: 0,
        center: true,
      },
      768: {
        loop: true,
        autoplay: false,
        center: true,
        items: 1,
        margin:40,
        stagePadding: 40,
        autoWidth: false
      },
      992: {
        loop: true,
        autoplay: false,
        center: true,
        items: 1,
        margin:60,
        stagePadding: 60,
        autoWidth: true
      },
      1200: {
        loop: true,
        autoplay: false,
        center: true,
        items: 1,
        margin:90,
        stagePadding: 90,
        autoWidth: true
      }
    },
  });
}
