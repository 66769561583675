import {sleep} from "./tools";

const body = document.querySelector('body');
const discoverLinksArea = document.querySelector('.Discover__links');
const cursorDiscoverLight = document.querySelector('#cursorLight');

const discoverLinksImageArea = document.querySelectorAll('.js-discover-image-link');
const cursorDiscoverImage = document.querySelector('#cursorLightImage');

const discoverLinksDarkArea = document.querySelectorAll('.js-discover-dark-link');
const cursorDiscoverDark = document.querySelector('.Discover__cursor.Discover__cursor-dark.js-cursor-discover');

let clientX=-100;
let clientY=-100;

const showCursorDiscoverLight = () => {
  if(!body.classList.contains('is-cursor-active')) {
    body.classList.add('is-cursor-active', 'is-cursor-discover-light-active');
  }



}

const disableCursorDiscoverLight = () => {
  body.classList.remove('is-cursor-active', 'is-cursor-discover-light-active');
}

const mouseMoveDiscoverLight = () => {
  let cursorWidth= cursorDiscoverLight.offsetWidth / 2;
  let cursorHeight= cursorDiscoverLight.offsetHeight / 2;

  cursorDiscoverLight.style.transform = `translate3d(${clientX - cursorWidth}px, ${clientY - cursorHeight}px,0)`;

  window.requestAnimationFrame(mouseMoveDiscoverLight)
};

const showCursorDiscoverLightImage = () => {
  if(!body.classList.contains('is-cursor-active')) {
    body.classList.add('is-cursor-active', 'is-cursor-discover-image-light-active');
  }
}

const disableCursorDiscoverLightImage = () => {
  body.classList.remove('is-cursor-active', 'is-cursor-discover-image-light-active');
}

const mouseMoveDiscoverLightImage = () => {
  let cursorWidth= cursorDiscoverImage.offsetWidth / 2;
  let cursorHeight= cursorDiscoverImage.offsetHeight / 2;

  cursorDiscoverImage.style.transform = `translate3d(${clientX - cursorWidth}px, ${clientY - cursorHeight}px,0)`;

  window.requestAnimationFrame(mouseMoveDiscoverLightImage)
};

const showCursorDiscoverDark = () => {
  if(!body.classList.contains('is-cursor-active')) {
    body.classList.add('is-cursor-active', 'is-cursor-discover-dark-active');
  }
};

const disableCursorDiscoverDark = () => {
  body.classList.remove('is-cursor-active', 'is-cursor-discover-dark-active');
};

const mouseMoveDiscoverDark = () => {
  let cursorWidth= cursorDiscoverDark.offsetWidth / 2;
  let cursorHeight= cursorDiscoverDark.offsetHeight / 2;

  cursorDiscoverDark.style.transform = `translate3d(${clientX - cursorWidth}px, ${clientY - cursorHeight}px,0)`;

  window.requestAnimationFrame(mouseMoveDiscoverDark)
};

if(discoverLinksArea) {

  discoverLinksArea.addEventListener('mouseenter', () => {
    showCursorDiscoverLight();
    cursorDiscoverLight.classList.add('Discover__cursor-up')
  });

  discoverLinksArea.addEventListener('mouseleave', () => {
    disableCursorDiscoverLight();
    cursorDiscoverLight.classList.remove('Discover__cursor-up')
  });

  discoverLinksArea.addEventListener('mousemove', (event) => {
    showCursorDiscoverLight();
    clientX = event.clientX;
    clientY = event.clientY;
  });

  window.requestAnimationFrame(mouseMoveDiscoverLight)
}

if(discoverLinksDarkArea) {

  discoverLinksDarkArea.forEach( (element) => {
    element.addEventListener('mouseenter', () => {
      showCursorDiscoverDark();
      cursorDiscoverDark.classList.add('Discover__cursor-up')
    });

    element.addEventListener('mouseleave', () => {
      cursorDiscoverDark.classList.remove('Discover__cursor-up')
      disableCursorDiscoverDark();
    });

    element.addEventListener('mousemove', (event) => {
      showCursorDiscoverDark();
      clientX = event.clientX;
      clientY = event.clientY;
    });

  });

  window.requestAnimationFrame(mouseMoveDiscoverDark)
}

if(discoverLinksImageArea) {

  discoverLinksImageArea.forEach( (element) => {
    element.addEventListener('mouseenter', () => {
      showCursorDiscoverLightImage();
      cursorDiscoverImage.classList.add('Discover__cursor-up')
    });

    element.addEventListener('mouseleave', () => {
      disableCursorDiscoverLightImage();
      cursorDiscoverImage.classList.remove('Discover__cursor-up')
    });

    element.addEventListener('mousemove', (event) => {
      showCursorDiscoverLightImage();
      clientX = event.clientX;
      clientY = event.clientY;
    });

  });

  window.requestAnimationFrame(mouseMoveDiscoverLightImage)
}

