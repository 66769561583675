import {sleep} from "../tools";

const simulateDownload = (progress) => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve(progress + 4);
    }, 17);
  }).then(res => res);
};

const progress = () => {
  const documentStyles = document.documentElement.style;
  (async () => {
    let progress = 0;

    while (progress < 100) {
      progress = await simulateDownload(progress);

      if (progress % 5 === 0) {
        documentStyles.setProperty('--loaderProgress', `${progress}%`);
      }
    }
  })();
}


export const loading = () => {
  document.onreadystatechange = function() {
    const loader = document.getElementById('loaderAnimation');

    if(loader) {
      if(document.readyState === "interactive") {
        document.body.classList.add('is-loader-active');
        document.querySelector('.PageTransition').classList.remove('PageTransition-reduce')
        document.querySelector("#loaderAnimation").classList.add('Loader--active');
        progress();
      }
      else if(document.readyState === "complete") {
        sleep(1000).then( () => {
          document.body.classList.remove('is-loader-active');
          document.querySelector(".PageTransition").classList.add('PageTransition-active');
        }).then( () => sleep(800))
          .then( () => {
            document.querySelector(".PageTransition").classList.add('PageTransition-reduce');
            document.querySelector(".PageTransition").classList.remove('PageTransition-active');
          })
          .then( () => {
            document.querySelector("#loaderAnimation").classList.remove('Loader--active');
            document.querySelector("#loaderAnimation").classList.add('Loader--hide')
          })
          .then ( () => sleep(800))
          .then ( () => {
            document.querySelector(".PageTransition").classList.remove('PageTransition-reduce');
          })
      }
    }
  };
}


