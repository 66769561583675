import {getLocale} from "./tools";

const locales = {
  fr: {
    amountLabel: 'Honoraires de ',
    amountPrice: '',
  },
  en : {
    amountLabel: 'Fees : ',
    amountPrice: '',
  }
}

export const translate = (key, params, locale) => {
  if(!locale || !locales[locale]) {
    locale = getLocale();
  }

  let str = locales[locale][key];

  if(typeof params === 'object' && params !== null) {
    Object.keys(params).forEach(key => str = str.split(`:${key}`).join(params[key]));
  }

  return str;
}

window.tranlate = translate;

export default locales;
