$('.js-remove-filter').on('click', (e) => {
  e.preventDefault();

  let type = $(e.currentTarget).data('type');

  if(type === "clearAll") {
    $(`input[name="city"]`).val('#');
    $(`input[name="area"]`).val(0);
    $(`input[name="price"]`).val('#');
    $(`input[name="rooms"]`).val('#');
  }
  else {
    $(`input[name="${type}"]`).val('#');
  }

  $('.js-search-action').click();
});

$('.Filters__toggle').on('click', (e) => {
  e.preventDefault();

  let target = $(e.currentTarget);

  $([document.documentElement, document.body]).animate({
    scrollTop: $('.Properties').offset().top,
  }, 100);

  $('.Filters__items').slideToggle('slow', () => {

    if(target.hasClass('Filters__toggle-open')) {
      $('.Backdrop').addClass('Backdrop-active');
    }
    else if (target.hasClass('Filters__toggle-close')) {
      $('.Backdrop').removeClass('Backdrop-active');
    }

  });
})
