export const formAlert = ($form, type, message, formType) => {
  let $alert = $form.find('.Alert');

  if ($alert.length < 1) {
    $alert = $('<div></div>');
    $form.parent().prepend($alert);
  }

  if(formType === "property-contact-form") {
    type = "light"
  }

  $alert.text(message);
  $alert.removeAttr('class');
  $alert.addClass('Alert');
  $alert.addClass('m-b-10');
  $alert.addClass(`Alert--${ type}`);
  $alert.removeClass('hidden');

  if(formType === "receive-alert-desk") {
    $('.ReceiveAlert').animate({
      scrollTop: $alert.offset().top - 50,
    }, 500);
  }
  else {
    $([document.documentElement, document.body]).animate({
      scrollTop: $alert.offset().top - 50,
    }, 500);
  }
}

export const send = ($target, action, type = '') => {
  grecaptcha.ready(() => {
    grecaptcha.execute('6LcOL_kcAAAAAEEH5JI0vaEO_oPbRc2_9Chqq-Z8', {action: 'submit'}).then((token) => {
      const formData = new FormData($target[0]);
      formData.append('action', action);
      formData.append('captcha', token);
      axios({
        method:'POST',
        url: wp_obj.ajax_url,
        data: formData,
      }).then((response) => {
        if(response.data === "success") {
          formAlert($target, 'success', 'Merci pour votre demande. Notre équipe revient vers vous au plus vite.', type);
          $target.trigger('reset');
        }
        else if (response.data === 'captcha') {
          formAlert($target, 'error', 'Une erreur inattendue est survenue. Veuillez réessayer ultérieurement.', type);
        }
        else if (response.data === 'email-error') {
          formAlert($target, 'error', 'Veuillez vérifier le format de l\'email saisie', type);
        }
        else {
          formAlert($target, 'error', 'Une erreur inattendue est survenue. Veuillez réessayer ultérieurement.', type);
        }
      }).catch( () => {
        formAlert($target, 'error', 'Une erreur inattendue est survenue. Veuillez réessayer ultérieurement.', type);
      });
    });
  });
}
