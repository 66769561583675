import EmblaCarousel from 'embla-carousel';
import { setupPrevNextBtns, disablePrevNextBtns } from "./slider-navigation";
import {isBeforeOrEqualDevice} from "./config";

export const  initSliders = () => {
  const wrap = document.querySelector('.embla');
  const viewport = document.querySelector('.embla__viewport');

  if(wrap && viewport) {
    const prevBtn = wrap.querySelector(".embla__button--prev");
    const nextBtn = wrap.querySelector(".embla__button--next");

    const embla = EmblaCarousel(viewport, {
      loop: true,
      slidesToScroll: isBeforeOrEqualDevice('sm') ? 1 : 2,
      draggable: true,
      align: "start",
      direction: "ltr",
      speed: isBeforeOrEqualDevice('sm') ? 2 : 1,
    })

    const disablePrevAndNextBtns = disablePrevNextBtns(prevBtn, nextBtn, embla);

    setupPrevNextBtns(prevBtn, nextBtn, embla);

    embla.on("select", disablePrevAndNextBtns);
    embla.on("init", disablePrevAndNextBtns);
  }
}
