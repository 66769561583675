import {enableBodyScroll, disableBodyScroll} from './body-scroll-lock.js';
import {initLightboxCarousel} from "../owl-carousel";

/**
 * @property {HTMLElement} element
 * @property {string[]} images Chemins des images de la lightbox
 * @property {string} url Image actuellement affichée
 **/
export default class Lightbox {

  static init() {
    const links = Array.from(document.querySelectorAll('.PropertyPresentation__right div[data-href$=".png"], .PropertyPresentation__right div[data-href$=".jpg"], .PropertyPresentation__right div[data-href$=".jpeg"],.PropertyImages div[data-href$=".png"], .PropertyImages div[data-href$=".jpg"], .PropertyImages div[data-href$=".jpeg"],.PropertyPresentation__left div[data-href$=".png"], .PropertyPresentation__left div[data-href$=".jpg"], .PropertyPresentation__left div[data-href$=".jpeg"]'))

    const gallery = links.map(link => link.getAttribute('data-href'));

    links.forEach(link => link.addEventListener('click', e => {

      let rank = link.getAttribute('data-rank');

      new Lightbox(gallery, rank)
    }))
  }

  /**
   * @param {string} url URL de l'image
   * @param {string[]} images Chemins des images de la lightbox
   */
  /*constructor(url, images) {
    this.element = this.buildDOM(url)
    this.images = images
    this.loadImage(url)
    this.onKeyUp = this.onKeyUp.bind(this)
    document.body.appendChild(this.element)
    disableBodyScroll(this.element)
    document.addEventListener('keyup', this.onKeyUp)
  }*/

  constructor(images, rank) {
    this.images = images;
    this.element = this.buildDOM();
    document.body.appendChild(this.element)
    this.onKeyUp = this.onKeyUp.bind(this)
    disableBodyScroll(this.element)
    document.addEventListener('keyup', this.onKeyUp);

    initLightboxCarousel();

    $('.Lightbox__carousel').trigger('to.owl.carousel', [rank-1, 600, true])
  }

  /**
   * @param {string} url URL de l'image
   */
  loadImage (url) {
    this.url = null
    const image = new Image()
    const container = this.element.querySelector('.Lightbox__container')
    const loader = document.createElement('div')
    loader.classList.add('Lightbox__loader')
    container.innerHTML = ''
    container.appendChild(loader)
    image.onload = () => {
      container.removeChild(loader)
      container.appendChild(image)
      this.url = url
    }
    image.src = url
  }

  /**
   * @param {KeyboardEvent} e
   */
  onKeyUp (e) {
    if (e.key == 'Escape' || e.key == "Esc") {
      this.close(e)
    }
  }

  /**
   * Ferme la ligthbox
   * @param {MouseEvent|KeyboardEvent} e
   */
  close (e) {
    this.element.classList.add('fadeOut')
    enableBodyScroll(this.element)
    window.setTimeout(() => {
      this.element.parentElement.removeChild(this.element)
    }, 500)
    document.removeEventListener('keyup', this.onKeyUp)
  }


  /**
   * @param {string} url URL de l'image
   * @return {HTMLElement}
   */
  buildDOM() {
    const dom = document.createElement('div');
    dom.classList.add('Lightbox');

    let html = `<button class="Lightbox__close"></button>
        <div class="Lightbox__container"><div class="Lightbox__carousel owl-carousel">`;

    if(this.images.length > 0) {
      this.images.forEach((url, index) => {
        html += `<div class="Lightbox__image" data-dot="${index+1}" data-rank="${index+1}"><img src="${url}" /></div>`;
      });
    }

    html += `</div></div>`;

    dom.innerHTML = html;
    dom.querySelector('.Lightbox__close').addEventListener('click', this.close.bind(this));
    return dom;
  }

}

