import { Back, Power4, gsap } from 'gsap';

const burgerUpper = document.querySelector('.BurgerIcon__upper');
const burgerLast = document.querySelector('.BurgerIcon__lower');
const burgerMiddle = document.querySelector('.BurgerIcon__middle');

const burgerLight = document.querySelector('.BurgerIcon--light');

const bgColor = (burgerLight)? "#000000" : "#FFFFFF";

const Burger = gsap.timeline({
  paused: true,
  reversed: true,
});
Burger
  .to(burgerUpper, {
    duration: 0.1,
    ease: Back.easeInOut.config(1),
    rotation: 45,
    y: '10.12px',
    transformOrigin: 'center',
    backgroundColor: bgColor,
    width: '32px'
  }, 0.25)
  .to(burgerLast, {
      duration: 0.1,
      ease: Back.easeInOut.config(1),
      rotation: -45,
      y: '10.12px',
      transformOrigin: 'center',
      backgroundColor: bgColor,
      width: '32px'
    },
    0.25);

export default Burger;
