import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { isBeforeOrEqualDevice } from "./config";


const handleIntersectPresentationImage = (entry) => {
  let $sub = isBeforeOrEqualDevice('xxl') ? 400 : 420;

  if(entry[0].intersectionRatio > 0) {

      const heightDiff = document.querySelector('#presentationImage').offsetHeight - ((document.querySelector('.Presentation').offsetHeight - $sub));

      let y = isBeforeOrEqualDevice('xxl') ? '14%' : '15%';

      gsap.fromTo("#presentationImage", {
          y: heightDiff
        }, {
          y: y,
          ease: "none",
          scrollTrigger: {
          trigger: "#presentationImage",
          start: "top bottom",
          end: "bottom top",
          scrub: true
        }
      });
  }
}

const handleIntersectHomeImage = (entry) => {
  let $sub = isBeforeOrEqualDevice('xxl') ? -200 : -220;

  if(entry[0].intersectionRatio > 0) {

    const heightDiffBottom = document.querySelector('#homeImg').offsetHeight - ((document.querySelector('#homeImg').offsetHeight - $sub));

    let yBottom = isBeforeOrEqualDevice('xxl') ? '14%' : '15%';

    gsap.fromTo("#homeImg", {
      y: heightDiffBottom
    }, {
      y: yBottom,
      ease: "none",
      scrollTrigger: {
        trigger: "#homeImg",
        start: "top bottom",
        end: "bottom top",
        scrub: true,
      }
    });
  }
}


export const parallax = () => {
  let width = $(window).width();

  if(width > 1380 && document.querySelector('.Presentation__image') && document.querySelector('.HomeImage__image')) {

    gsap.registerPlugin(ScrollTrigger)

    const observerElementPresentation = document.querySelector('.Presentation__image');
    const observerOptions = {
      root: null,
      rootMargin: '0px 20px',
      threshold: 0
    };

    const observerPresentation = new IntersectionObserver(handleIntersectPresentationImage, observerOptions);
    observerPresentation.observe(observerElementPresentation)

    const observerElementHomeImage = document.querySelector('.HomeImage__image');

    const observerHomeImage = new IntersectionObserver(handleIntersectHomeImage, observerOptions);
    observerHomeImage.observe(observerElementHomeImage)
  }
}

