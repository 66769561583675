var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"width":"100%"}},[_c('vue-tel-input',{attrs:{"defaultCountry":"fr","inputOptions":{
                  name: 'phone',
                  autocomplete: true,
                  required: true,
                  maxlength: _vm.maxLength
               },"mode":"national","styleClasses":"FormGroup__phone","validCharactersOnly":true,"dropdownOptions":{
                 showFlags: false,
                 showDialCodeInSelection: true,
                 showDialCodeInList: true
               }},on:{"input":_vm.handleChange,"validate":_vm.handleValidate,"country-changed":_vm.handleCountryChanged},model:{value:(_vm.value),callback:function ($$v) {_vm.value=$$v},expression:"value"}}),_vm._v(" "),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.valueFormatted),expression:"valueFormatted"}],attrs:{"type":"hidden","name":"phone-formatted"},domProps:{"value":(_vm.valueFormatted)},on:{"input":function($event){if($event.target.composing){ return; }_vm.valueFormatted=$event.target.value}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }