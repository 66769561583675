let autocompleteTimeout = null;

export const closeAutocompletion = () => {
  const search_result = document.querySelector('.Search__results');
  const search_group = document.querySelector('.Search__group-search');
  search_result.classList.remove('Search__results-open');
  search_group.classList.remove('Search__group-light');

  clearTimeout(autocompleteTimeout);

  autocompleteTimeout = setTimeout(() => {
  }, 300);
}

export const openAutocompletion = () => {
  const search_result = document.querySelector('.Search__results');
  const search_group = document.querySelector('.Search__group-search');
  search_result.classList.add('Search__results-open');
  search_group.classList.add('Search__group-light');

  clearTimeout(autocompleteTimeout);

  autocompleteTimeout = setTimeout(() => {
  }, 300);
}

export const searchProperties = (query) => {
  grecaptcha.ready(() => {
    grecaptcha.execute('6LcOL_kcAAAAAEEH5JI0vaEO_oPbRc2_9Chqq-Z8', {action: 'submit'}).then((token) => {

      const type = document.querySelector('.js-search-input').attributes['data-type'].value;
      const country = document.querySelector('.js-search-input').attributes['data-country'].value;

      const formData = new FormData();
      formData.append('action', 'nl_search_properties');
      formData.append('s', query);
      formData.append('captcha', token);
      formData.append('type', type);
      formData.append('country', country);

      axios({
        method:'POST',
        url: wp_obj.ajax_url,
        data: formData,
      }).then((response) => {
        const prefix = (wp_obj.locale === "fr") ? 'biens' : 'properties';

        let $result = "";
        if(response.data && response.data.data && !response.data.data.message && response.data.data.length > 0 ) {
          response.data.data.forEach( (element) => {
            if(element.district) {
              $result += `<div class="Search__results-item"><a href="/${wp_obj.locale}/${prefix}/${element.reference}/">${element.district.name} - ${element.city.name} - ${element.area.value} m2</a></div>`
            }
            else {
              $result += `<div class="Search__results-item"><a href="/${wp_obj.locale}/${prefix}/${element.reference}/">${element.city.name} - ${element.area.value} m2</a></div>`
            }
          });
        }
        else if (response.data && response.data.data.message) {
          $result = `<div class="Search__results-empty">${response.data.data.message}</div>`;
        }
        document.querySelector('.Search__results-inner').innerHTML = "";
        document.querySelector('.Search__results-inner').innerHTML = $result;
      }).catch( () => {
        console.log('error search')
      });
    });
  });
}

