import ScrollReveal from "scrollreveal";

export const initScrollReveal = () => {

  const sr = ScrollReveal();

  sr.reveal('.Heading__title, .Heading__subtitle, .HomeStories__title, .Contact__title, .Section__title, .PropertyPresentation__title', {
    duration: 1100,
    origin: 'bottom',
    distance: '50px',
    scale: 1,
    delay: 400
  }, 100);

  sr.reveal('.Heading__footer-description, .Presentation__title, .Presentation__description, .HomeSearch__title, .Section__description, .Post__title', {
    duration: 900,
    origin: 'bottom',
    distance: '50px',
    scale: 1,
    delay: 100
  }, 100);

}
