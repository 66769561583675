<template>
  <div style="width: 100%">
    <vue-tel-input v-model="value"
                   defaultCountry="fr"
                   :inputOptions="{
                    name: 'phone',
                    autocomplete: true,
                    required: true,
                    maxlength: maxLength
                 }"
                   @input="handleChange"
                   @validate="handleValidate"
                   @country-changed="handleCountryChanged"
                   mode="national"
                   styleClasses="FormGroup__phone"
                   :validCharactersOnly="true"
                   :dropdownOptions="{
                   showFlags: false,
                   showDialCodeInSelection: true,
                   showDialCodeInList: true
                 }"
    ></vue-tel-input>
    <input type="hidden" name="phone-formatted" v-model="valueFormatted">
  </div>
</template>
<script>
import { VueTelInput } from 'vue-tel-input';

export default {
  name: 'phone-number-input',
  components: {
    VueTelInput
  },
  data: function() {
    return {
      value: null,
      valueFormatted: null,
      maxLength: 10,
      defaultMaxLength: 25
    }
  },
  methods: {
    handleChange(number, phoneObject) {
      this.valueFormatted = phoneObject.number;
    },
    handleValidate(phoneObject) {
      this.maxLength = this.defaultMaxLength;
      if(phoneObject.valid) {
        this.maxLength = phoneObject.nationalNumber.length
      }
    },
    handleCountryChanged(countryObject) {
      this.value = ""
    }
  }
}
</script>
