import './lib/bootstrap'
import { closeAutocompletion, openAutocompletion, searchProperties } from './lib/autocompletion';
import {closeNav, openNav} from './lib/menu';
import {closeInputArea, initSelects, openInputArea} from "./lib/search";
import {initSliders} from "./lib/slider";
import {initOwlCarousel} from "./lib/owl-carousel";
import {send} from "./lib/form";
import {closeReceiveAlert, openReceiveAlert} from "./lib/receive-alert";
import {initScrollReveal} from "./lib/scroll-reveal";
import Lightbox from "./lib/lightbox/lightbox";
import {isBeforeOrEqualDevice} from "./lib/config";

require('./lib/filter')
require('./lib/cursor-discover')


document.addEventListener("DOMContentLoaded", function (event) {
  initScrollReveal();

  Lightbox.init();

  //MENU NAV
  if(document.querySelector('.BurgerIcon__close')) {
    document.querySelector('.BurgerIcon__close').addEventListener('click', (e) => {
      closeNav();
    });
  }
  document.querySelector('.js-toggle-nav').addEventListener('click', () => {
    const header = document.querySelector('.Header');
    const burgerIcon = document.querySelector('.BurgerIcon');

    if(header.classList.contains('Header--open')) {
      closeNav();
    }
    else if(!burgerIcon.classList.contains('BurgerIcon__close')) {
      openNav();
    }
  });

  //SEARCH
  initSelects();
  $('.js-home-search').on('click', (e) => {
    e.preventDefault();

    let redirect = $('input[name="project_type"]').val(),
      city = $('input[name="city"]').val(),
      area = $('input[name="area"]').val(),
      rooms = $('input[name="rooms"]').val();

    if(redirect) {
      let url = new URL(redirect);

      if(city) {
        url.searchParams.append('city', city);
      }

      if(area) {
        url.searchParams.append('area', area);
      }

      if(rooms) {
        url.searchParams.append('rooms', rooms);
      }
      window.location.href = url;
    }
  });

  $('.js-search-action').on('click', (e) => {
    e.preventDefault();

    let city = $('input[name="city"]').val(),
      area = $('input[name="area"]').val(),
      rooms = $('input[name="rooms"]').val(),
      price = $('input[name="price"]').val(),
      url = new URL(`${window.location.origin}${window.location.pathname}`);

    if(city && city !== '#') {
      url.searchParams.append('city', city);
    }

    if(area && area  !== '#' && area > 0) {
      url.searchParams.append('area', area);
    }

    if(rooms && rooms  !== '#') {
      url.searchParams.append('rooms', rooms);
    }

    if(price && price  !== '#') {
      url.searchParams.append('price', price);
    }

    window.location.href = url;
  });

  $('.js-properties-next').on('click', (e) => {
    e.preventDefault();

    let currentTarget = $(e.currentTarget),
      currentTargetParent = currentTarget.parent(),
      maxFounded = currentTargetParent.data('max'),
      propertiesDisplayed = $('.Properties__property-show'),
      propertiesHidden = document.querySelectorAll('.Properties__property-hidden');

    propertiesHidden.forEach((element, index) => {
      let property = $(element),
        maxEnv = property.data('max');

      if(index < (maxEnv)) {
        property.removeClass('Properties__property-hidden');
        property.addClass('Properties__property-show');
      }
    });

    propertiesDisplayed = $('.Properties__property-show');

    if(parseInt(propertiesDisplayed.length) === parseInt(maxFounded)) {
      currentTargetParent.addClass('Cta__more-hidden');
    }
  });

  //SLIDERS
  initSliders();

  //OWL-CAROUSEL
  initOwlCarousel();

  /**
   * CONTACT FORM
   */
  $('.js-contact-form').on('submit', (e) => {
    e.preventDefault();
    const $form = $(e.currentTarget);
    send($form, 'nestyliving_submit_contact_form', 'contact');
    return false;
  });

  /**
   * JOIN US FORM
   */
  $('.js-join-us-form').on('submit', (e) => {
    e.preventDefault();
    const $form = $(e.currentTarget);
    send($form, 'nestyliving_submit_join_us_form', 'join-us');
    return false;
  });

  /**
   * SELL FORM
   */
  $('.js-sell-form').on('submit', (e) => {
    e.preventDefault();
    const $form = $(e.currentTarget);
    send($form, 'nestyliving_submit_sell_form', 'sell-form');
    return false;
  });

  /**
   * PROPERTY CONTACT FORM
   */
  $('.js-contact-property-form').on('submit', (e) => {
    e.preventDefault();
    const $form = $(e.currentTarget);
    send($form, 'nestyliving_submit_contact_property_form', 'property-contact-form');
    return false;
  })

  /**
   * RECEIVE ALERT FORM DESK
   */
  $('.js-submit-receive-alert').on('click', (e) => {
    const $form = $('.js-receive-alert');

    let type = isBeforeOrEqualDevice('lg') ? 'receive-alert-smart' : 'receive-alert-desk';

    send($form, 'nestyliving_submit_alert_form', type);
    return false;
  });

  /**
   * RECEIVE ALERT FORM MOBILE
   */
  $('.js-submit-receive-alert-mob').on('click', (e) => {
    const $form = $('.js-receive-alert-mob');
    let type = isBeforeOrEqualDevice('lg') ? 'receive-alert-smart' : 'receive-alert-desk';
    send($form, 'nestyliving_submit_alert_form', type);
    return false;
  });

  /**
   * RECEIVE ALERT SIDEBAR
   */
  $('.js-header-alert').on('click', function(e) {
    e.preventDefault();
    openReceiveAlert();
  });

  const $receiveAlert = $('.ReceiveAlert');
  $(document).mouseup( (e) => {
    if($receiveAlert.hasClass('is-open') && !$receiveAlert.is(e.target) && $receiveAlert.has(e.target).length === 0) {
      closeReceiveAlert();
    }
  });

  $('.js-close-receive-alert').on('click', (e) => {
    e.preventDefault();
    closeReceiveAlert();
  });


  /**
   * AUTOCOMPLETION
   */
  const search_input = document.querySelector('.js-search-input');
  if(search_input) {
    search_input.addEventListener('input', (e) => {
      const value = e.target.value;
      if(value.length >= 2) {
        openAutocompletion();
        searchProperties(value)
      }
      else {
        closeAutocompletion();
      }
    });
  }

  document.addEventListener('mouseup', e => {
    let $searchResults = $('.Search__results');
    if($searchResults && $searchResults.hasClass('Search__results-open') && !$searchResults.is(e.target) && $searchResults.has(e.target).length === 0) {
      closeAutocompletion();
    }

    let selectsInput = document.querySelectorAll('.FormGroup__select-area');
    if(selectsInput) {
      selectsInput.forEach( (element) => {
        let formGroup = $(element);
        const singleSelected = formGroup.find('.ss-single-selected');
        const type = formGroup.data('type');

        if(singleSelected.hasClass('ss-open-below') && !singleSelected.is(e.target) && formGroup.has(e.target).length === 0) {
          closeInputArea(e.currentTarget, type);
        }
      })
    }
  });


  /**
   * FORM LABEL CLICK
   */
  $('.FormGroup__label').on('click', (e) => {
    let next = $(e.currentTarget).next();

    if(next.is('select')) {
      next.next().find('.ss-single-selected').click();
    }
    else if(next.is('input')) {
      next.focus();
    }
  });

  /**
   * FORM AREA MIN
   */
  $('.FormGroup__select-area .ss-single-selected').on('click', (e) => {
    const type = $(e.currentTarget).parent().parent().data('type');
    const singleSelect = $('.FormGroup__select-area .ss-single-selected');

    if(singleSelect.hasClass('ss-open-below')) {
      closeInputArea(e.currentTarget, type);
    }
    else {
      openInputArea(e.currentTarget, type);
    }
  });


  $('.FormGroup__select-area input').on('change', (e) => {
    const target = $(e.currentTarget);
    const targetParent = target.parent().parent().parent().parent().parent();

    const type = targetParent.data('type');
    const placeholder = targetParent.find('.ss-single-selected .placeholder');
    const value = e.currentTarget.value;
    const name = e.currentTarget.name;
    const locale = window.wp_obj.locale;

    let label = "";
    if(type === "area") {
      if(name === "area") {
        label = (locale === "fr") ? 'Surface min' : 'Area min';
      }
      else if (name === "area_min") {
        label = (locale === "fr") ? 'Surface min' : 'Area min';

        let areaMax = $('input[name="area_max"]');
        if(areaMax) {
          areaMax.attr('min', value);
        }
      }
      else if (name === "area_max") {
        label = (locale === "fr") ? 'Surface max' : 'Area max';
      }
    }
    else if (type === "price") {
      if(name === "price_min") {
        label = (locale === "fr") ? 'Prix min' : 'Prix min';

        let priceMax = $('input[name="price_max"]');
        if(priceMax) {
          priceMax.attr('min', value);
        }
      }
      else if(name === "price_max") {
        label = (locale === "fr") ? 'Prix max' : 'Prix max';
      }
    }

    if(value > 0) {
      const unit = (type === "area") ? 'm2' : '€';
      placeholder.html('').html(`${value} ${unit}`)
    }
    else {
      placeholder.html('').html(label)
    }
  })
});

/**
 * PAGE TRANSITION
 */
window.onload= () => {
  const anchors = document.querySelectorAll('a');
  const loader = document.getElementById('loaderAnimation');

  if(!loader) {
    setTimeout (() => {
      document.querySelector('.PageTransition').classList.remove('PageTransition-reduce');
    }, 800);

  }
  /* for(let i = 0 ; i < anchors.length; i++) {
     const anchor = anchors[i];

     anchor.addEventListener('click', (e) => {
       e.preventDefault();

       const link = e.currentTarget.href;
       const target = e.currentTarget.target;


       if(link && link.indexOf('#') < 0) {
         if('_blank' != target && link.indexOf('wp-admin') < 0) {

           document.querySelector('.PageTransition').classList.add('PageTransition-active');

           setInterval( () => {
             window.location.href = link;
           }, 500)

         }
         else if('_blank' === target && link.indexOf('wp-admin') < 0) {
           window.open(link, '_blank');
         }
         else if(link.indexOf('wp-admin') > 0) {
           window.location.href = link;
         }
       }
     });
   } */

}


