export const getLocale = () => {
  if (window.wp_obj) {
    return window.wp_obj.locale;
  }

  return window.LOCALE ? window.LOCALE : 'fr';
};

export const money = (amount, withCents) => {
  return (amount / 100).toFixed(withCents !== false ? 2 : 0) + ' €';
}

export const sleep = (ms) => {
  return new Promise(resolve => setTimeout(resolve, ms));
}

window.getLocale = getLocale();
window.money = money;
