let alertTimeout;

export const closeReceiveAlert = () => {
  const backdrop = document.querySelector('.Backdrop');
  const receiveAlert = document.querySelector('.ReceiveAlert');
  const body = document.querySelector('body');
  const menuItems = document.querySelectorAll('.js-header-alert');

  clearTimeout(alertTimeout);
  alertTimeout = setTimeout( () => {
    backdrop.classList.remove('Backdrop-active');

    receiveAlert.classList.remove('is-open');
    body.classList.remove('no-scroll');

    menuItems.forEach((element) => {
      $(element).removeClass('current-menu-item');
    });
  }, 200);
}

export const openReceiveAlert = () => {
  const backdrop = document.querySelector('.Backdrop');
  const receiveAlert = document.querySelector('.ReceiveAlert');
  const body = document.querySelector('body');
  const menuItems = document.querySelectorAll('.js-header-alert');

  backdrop.classList.add('Backdrop-active');
  clearTimeout(alertTimeout);

  alertTimeout = setTimeout( () => {
    receiveAlert.classList.add('is-open');
    body.classList.add('no-scroll');

    menuItems.forEach((element) => {
      $(element).addClass('current-menu-item')
    })
  }, 100);
}
